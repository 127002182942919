import React, {useState, useEffect}  from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate,useParams,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ShipmentTracking(){
    let navigate = useNavigate();
    const { slip_no } =useParams();
    const [status, setStatus] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    useEffect(()=>{
        checkthiredpartyvalue();
    },[]);

    const checkthiredpartyvalue = async() =>{
        //http://localhost/SBDL-Courier-X-backend/public/totalogix.php
        //https://www.shreebanshidharlogistics.com/public/totalogix.php
        await axios.get('https://www.shreebanshidharlogistics.com/public/totalogix.php', {
            params: {
                awbno: slip_no,
            },
            headers: {
                'Content-Type': 'application/json', // Specify content type
                // Add more headers as needed
            }
          })
            .then(function (response) {
                GetTrackingDetails();
              console.log("////////////",response);
            })
            .catch(function (error) {
              console.log("//error",error);
              GetTrackingDetails();
            });
           
    }

    const GetTrackingDetails=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Getshipmentdetailsbyslipno',
            headers:headers1,
            data: {
                slip_no: slip_no
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var statusdata = response.data.value.shipmentallstatus;
                setStatus(statusdata);
            }
            else
            {
                setStatus([]);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipments Tracking</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">AWB #{slip_no} </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="row shipment_detail_sho">
                <div className="col-md-3"></div>
                    <div className="col-12 col-md-6">
                        
                        <div className="card mb-3 sticky-top">
                            <div className="card-body">
                            {status !=undefined && status !='' ?
                            <>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-2">
                                        <div className="icon_sm">
                                            <i className="bx bx-search"></i>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md">
                                        <h3>Shipment Tracking</h3>
                                    </div>
                                </div>
                                
                                <div className="timeline-wrapper"> 
                                    <ul className="StepProgress">
                                        {status.map((st,i)=>{
                                            return(
                                        <li className="StepProgress-item is-done" key={i}>
                                            <div className="bold time">
                                                <h4>{st.pickup_time}</h4>
                                                <h6>{st.pickup_date}</h6>
                                                <h5>{st.city}</h5>
                                            </div> 
                                            <div className="bold">{st.Details}
                                                <p>{st.Activites}</p>
                                                <small>Updated By: {st.name}     </small>
                                            </div>
                                        </li>
                                        );
                                    })}
                                    </ul>
                                </div>
                                </>
                                :
                                <h6 className="text-danger text-center pt-2">Recode Not Found</h6>
                                
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default ShipmentTracking;